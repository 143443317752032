.quiz-root-container {
  height: 100%;
  display: flex;
  width: 100%;
  .result-container {
    display: flex;
    width: 100%;
    .answer-container {
      display: flex;
      width: 100%;
      .wrong-answer-container {
        display: flex;
        width: 100%;
        background: url("../../assets/backgrounds/zebra-red.png") no-repeat;
        background-size: cover;
      }
      .correct-answer-container {
        display: flex;
        width: 100%;
        background: url("../../assets/backgrounds/zebra-green.png") no-repeat;
        background-size: cover;
      }
    }
    .chapter-end-container {
      display: flex;
      width: 100%;
      background: url("../../assets/backgrounds/zebra-blue.png") no-repeat;
      background-size: cover;
    }
  }
}

// .fade-in-container {
//   animation: fadeIn 0.5s;
//   -webkit-animation: fadeIn 0.5s;
//   -moz-animation: fadeIn 0.5s;
//   -o-animation: fadeIn 0.5s;
//   -ms-animation: fadeIn 0.5s;
// }
// @keyframes fadeIn {
//   0% {opacity:0;}
//   100% {opacity:1;}
// }

// @-moz-keyframes fadeIn {
//   0% {opacity:0;}
//   100% {opacity:1;}
// }

// @-webkit-keyframes fadeIn {
//   0% {opacity:0;}
//   100% {opacity:1;}
// }

// @-o-keyframes fadeIn {
//   0% {opacity:0;}
//   100% {opacity:1;}
// }

// @-ms-keyframes fadeIn {
//   0% {opacity:0;}
//   100% {opacity:1;}
// }
