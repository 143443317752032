.ranking-root {
  height: 100%;
  .ranking-root-container {
    display: flex;
    background: url("../../assets/backgrounds/regions-score.png") no-repeat;
    background-size: cover;
    height: 100%;
    flex-direction: column;
    align-items: center;
    padding: 0 40px;
    overflow: auto;
    .title {
      margin-top: 20%;
      color: #fff;
      font-size: 32px;
      font-weight: 400;
      letter-spacing: 0.5714286px;
    }
    .global-score-container {
      display: flex;
      width: 55%;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;
      margin-top: 10px;
      .global-score {
        color: #febd08;
        font-size: 28px;
        font-weight: 400;
        white-space: nowrap;
      }
      .eu-flag {
        width: 40%;
      }
    }
    .regions-score-container {
      width: 50%;
      .region-score-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 30px 0 5px;
        margin-bottom: 5px;
        &.selected-region {
          background: #fff;
          border-radius: 16px;
        }
        .flag {
          display: flex;
          .icon {
            width: 30px;
          }
        }
        .score {
          color: #fff;
          &.selected-region {
            color: #000;
          }
        }
      }
    }
  }
}

.customPopupPaperMobile {
  background-color: #181c34 !important;
  padding: 20px;
}
