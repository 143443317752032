.chapter-end-root-container {
  display: flex;
  justify-content: center;
  width: 100%;
  .officer-wild {
    position: absolute;
    right: 18%;
    bottom: 0;
    height: 100%;
    &.mobile-version {
      right: 0;
    }
    &.tablet-device {
      right: 5%;
    }
    .officer-wild-on-right-side {
      .officer-wild-container {
        .officer {
          width: 12%;
        }
      }
      .info-container {
        align-items: center;
        min-height: 30%;
      }
      .text-container {
        padding: 40px 50px 50px 50px;
        max-width: 75%;
        margin-right: 0%;
        background: url("../../assets/backgrounds/officer-wild-info-chapter-end.svg");
        background-size: cover;
        height: 50vh;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        .title-container {
          .title {

            @media screen and (max-height: 730px) {
              top: 2vh;
            }

            background: linear-gradient(to top, #040718 45%, transparent 45%);
            color: #fff;
            position: absolute;
            top: 0;
            left: -10%;
            font-size: 3vw;
            text-align: center;
            padding: 0 50px;
            transform: rotate(-1deg);
            letter-spacing: 0;
            line-height: 90px;
            text-shadow: 0 0 4px rgba(0,0,0,0.06);
          }
        }
        .content-container {
          height: 100%;
          width: 90%;
          position: relative;
          top: 10%;
          .content {
            text-align: center;
            color: #070c1f;
            display: flex;
            flex-grow: 1;
            align-items: center;
            padding: 0 60px;
            font-size: 1.5vw;
          }
        }
      }
    }
    .officer-wild-under-content {
      .officer-wild-container {
        .officer {
          position: absolute;
          bottom: 60px;
          left: 10px;
          width: 25%;
        }
      }
      .info-container {
        align-items: center;
        height: 100%;
      }
      .text-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .title-container {
          .title {
            background: linear-gradient(to top, #040718 45%, transparent 45%);
            color: #fff;
            position: absolute;
            top: -15%;
            z-index: 1;
            &.mobile-version {
              top: -5%;
              left: 0;
              font-size: 35px;
              padding: 0 15px;
            }
          }
        }
        .content-container {
          .content {
            text-align: center;
            color: #070c1f;
            display: flex;
            flex-grow: 1;
            align-items: center;
            padding: 20px;
            font-weight: 300;
          }
        }
      }

      .bubble-triangle {
        display: none;
      }
    }
  }
  .chapter-number-container {
    position: absolute;
    top: 10%;
    color: white;
  }
  .skip-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 10%;
    position: absolute;
    .skip-button {
      cursor: default;
    }
    .label {
      margin-bottom: 5px;
      color: #fff;
    }

    .MuiFab-colorInherit {
      background: #131a42 !important;
    }
  }

  .buttons-container {
    display: flex;
    flex-direction: row;
    position: absolute;
    bottom: 5%;
    width: 20%;
    justify-content: space-around;

    .read-more-button-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .read-more-label {
        color: #7A7F9C;
        letter-spacing: 0.24px;
        margin-top: 5px;
      }

      .MuiFab-colorInherit {
        background: #131a42 !important;
      }
    }
    .continue-button-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .label {
        color: #e7e7e7;
        margin-top: 5px;
        text-align: center;
      }
      .MuiFab-colorInherit {
        color: #060b1c !important;
        background: #ffc200 !important;
      }
    }
  }
}
