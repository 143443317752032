.region-selection-page-root-container {
  height: 100%;
  background: url("../../assets/backgrounds/zebra-yellow.png") no-repeat;
  background-size: cover;

  display: flex;
  justify-content: center;
  align-items: center;
  &.mobile-version {
    min-height: 100%;
    height: auto;
  }
  .selection-container {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    -webkit-box-shadow: -1px -1px 0px 10px rgba(0, 0, 0, 0.05);
    box-shadow: -1px -1px 0px 10px rgba(0, 0, 0, 0.05);
    transform: skewY(-1deg);
    height: 75%;
    width: 60%;
    border-radius: 2px;
    -webkit-background-clip: padding-box; /* for Safari */
    background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
    padding-bottom: 30px;

    &.mobile-version {
      min-height: 80vh;
      height: auto;
      margin: 50px 0 50px 0;
      width: 85%;
    }

    .title-container {
      display: flex;
      justify-content: center;
      padding: 45px;
      font-size: 3rem;
      color: #1d295c;
      transform: skewY(1deg);
      &.mobile-version {
        font-size: 36px;
      }
    }
    .regions-columns {
      overflow-y: auto;
      transform: skewY(1deg);
    }
  }
}
