.correct-answer-root {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  .center-container {
    width: 100% !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .details-container {
      width: 100%;
      min-height: 30%;
      display: flex;
      justify-content: flex-end;
      position: relative;
      margin-top: 10%;
      .image-container {
        width: 70%;
        display: block;
        &.mobile-version {
          width: 95%;
        }
        .cage {
          width: 110%;
          height: auto;
          &.mobile-version {
            width: 165%;
          }
        }
      }
      .answer-container {
        display: flex;
        justify-content: flex-start;
        padding-top: 40px;
        align-items: center;
        flex-direction: column;
        min-height: 48%;
        width: 38%;
        background: url("../../assets/backgrounds/correct-answer-background.svg")
          no-repeat;
        background-size: cover;
        position: absolute;
        top: -37%;
        right: 29%;
        border-radius: 2px;
        &.mobile-version {
          padding-top: 20px;
          width: 82%;
          top: -40%;
          right: 9%;
        }

        .correct-answer {
          color: #fff;
          font-size: 1.5em;
          text-align: center;

          &.mobile-device {
            padding-bottom: 50px;
            margin-top: 5px;
          }
        }
        .label {
          color: #108d63;
          font-size: 11px;
          font-weight: 600;
        }
        .checks-container {
          display: flex;
          width: 100%;
          align-items: center;
          position: absolute;
          bottom: 15%;
          .not-checked {
            background: #083a2a;
            padding: 5px;
            border-radius: 50%;
            position: absolute;
          }
          .checked {
            background: #129165;
            padding: 10px;
            border-radius: 50%;
            position: absolute;
            &.mobile-version {
              padding: 5px;
            }
            .check {
              display: block;
            }
          }

          div:nth-child(even) {
            margin-bottom: 10px;
          }
          div:nth-child(1) {
            left: 5%;
          }
          div:nth-child(2) {
            left: 14%;
          }
          div:nth-child(3) {
            left: 24%;
          }
          div:nth-child(4) {
            left: 34%;
          }
          div:nth-child(5) {
            left: 44%;
          }
          div:nth-child(6) {
            left: 54%;
          }
          div:nth-child(7) {
            left: 64%;
          }
          div:nth-child(8) {
            left: 74%;
          }
          div:nth-child(9) {
            left: 84%;
          }
          div:nth-child(10) {
            left: 94%;
          }
        }
      }
    }

    .finish-container {
      display: flex;
      height: 100%;
      position: relative;
      &.mobile-version {
        align-items: flex-end;
      }
      .info-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        width: 100%;
        top: 25%;
        &.mobile-version {
          top: 15%;
        }
        .cup-container {
          background: #129165;
          padding: 15px;
          border-radius: 50%;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          .cup {
            display: block;
            position: absolute;
            top: -20px;
          }
        }
        .congratulations-text {
          font-weight: 600;
          font-size: 11px;
          color: #DEDEDE;
          letter-spacing: 1.83px;
          line-height: 26px;
          margin-top: 16px;
        }
        .text {
          color: #fff;
          max-width: 30%;
          display: flex;
          text-align: center;
          &.mobile-version {
            max-width: 80%;
          }
          font-weight: 300;
          font-size: 24px;
          letter-spacing: 0;
          line-height: 27px;
          text-shadow: 0 0 4px rgba(0,0,0,0.06);
        }
      }
      .image-container {
        .free {
          height: 97%;
          transform: scale(1.19);
          position: relative;
          right: 140px;
          width: 120%;
          &.mobile-version {
            width: 200%;
            transform: none;
            position: inherit;
          }
        }
        &.mobile-version {
          margin-bottom: 10%;
          display: flex;
          justify-content: center;
        }
      }
    }
    .continue-button-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 1.5px;
      position: absolute;
      bottom: 5%;
      .label {
        color: #e7e7e7;
        margin-top: 5px;
      }
      .MuiFab-colorInherit {
        color: #d5d5d5 !important;
        background: #033626 !important;
      }
    }
  }
}
