$desiredAnswerFontColor: #1D295C;

.wrong-answer-root {
  width: 100%;
  display: flex;
  justify-content: center;
  .center-container {
    width: 75% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    .details-container {
      width: 100%;
      min-height: 30%;
      background: url("../../assets/backgrounds/wrong-background.svg") no-repeat;
      background-size: cover;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 10px;
      transform: skew(-2deg);
      flex-direction: column;
      border-bottom-right-radius: 3px;
      border-bottom-left-radius: 3px;

      .wrong-answer-icon {
        position: absolute;
        background: #d65556;
        border-radius: 50%;
        top: -5px;
        width: 28px;
        height: 28px;
        display: flex;
        justify-content: center;
        padding: 6px;
        img {
          display: block;
        }
      }
      .info-text {
        &.mobile-version {
          width: 70%;
          top: 40px;
          position: absolute;
          padding: 0;
          margin-top: 0;
        }
        color: #d65556;
        font-size: 11px;
        font-weight: 800;
        text-align: center;
        letter-spacing: 1.5px;
        padding: 20px;
        margin-top: 20px;

      }

      .wrong-answer-wrapper {
        display: flex;
        flex-grow: 1;
      }

      .wrong-answer {
        color: #fff;
        font-size: 3em;
        font-weight: 400;
        text-align: center;
        display: flex;
        align-items: center;
        position: relative;
        justify-content: center;
        margin-bottom: 10px;
        .strikethrough {
          position: absolute;
          width: 100%;
          min-width: 15vw;
          &.mobile-device {
            min-width: 50vw;
          }
        }
        &.mobile-device {
          margin-bottom: 0px;
          padding-top: 70px;
          padding-bottom: 50px;
        }
      }
      .correct-answer-container {
        background: url("../../assets/backgrounds/desired-answer.png") no-repeat;
        background-size: cover;
        position: absolute;
        width: fit-content;
        bottom: -30px;
        display: flex;
        align-items: center;
        transform: skew(-2deg);
        padding: 40px 40px 15px 40px;
        margin-right: 10px;
        .correct-answer-icon {
          margin-right: 20px;
        }
        .correct-answer {
          display: flex;
          flex-grow: 1;
          flex-wrap: wrap;
          color: $desiredAnswerFontColor;
        }
      }
    }

    .correct-answer-container-mobile-version {
      position: relative;
      bottom: 4%;
      max-width: 65vw;
      width: fit-content;
      min-height: 7vh;
      display: flex;
      align-items: center;
      border-radius: 2px;
      padding: 8px 20px;
      transform: skewX(-1deg);
      background: #FFF;

      .translated-container {
        position: absolute;
        background: #FFF;
        height: 6px;
        width: calc(100% - 1px);
        top: -2px;
        left: 1px;
        border-radius: 2px;
        transform: rotate( -1deg);
      }

      .correct-answer-icon {
        margin-right: 20px;
      }
      .correct-answer {
        display: flex;
        flex-grow: 1;
        flex-wrap: wrap;
        color: $desiredAnswerFontColor;
      }
    }
    .continue-button-container {
      display: flex;
      flex-direction: column;
      border-radius: 1.5px;
      position: absolute;
      bottom: 5%;
      .label {
        color: #e7e7e7;
        margin-top: 5px;
      }
      .MuiFab-colorInherit {
        color: #d5d5d5 !important;
        background: #4f1212 !important;
      }
    }
  }
}
