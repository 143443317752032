.ranking-page-root-container {
  display: flex;
  height: 100%;
  background: url("../../assets/backgrounds/zebra-blue.png") no-repeat;
  background-size: cover;
  &.mobile-device {
    align-items: center;
  }
  .laptop-device-container {
    display: flex;
    width: 100%;
    .left-container {
      height: 100%;
    }
    .image-container {
      position: relative;
      display: flex;
      justify-content: center;
      flex-grow: 1;

      .ranking-progress {
        width: 100%;
        object-fit: contain;
      }
    }
    .language-selection {
      position: absolute;
      top: 3%;
      right: 3%;
      left: 3%;
      width: 94%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      &.mobile-version {
        margin-right: 15%;
      }
    }
    .progresive-bar-container {
      flex: 2;
      background: url("../../assets/backgrounds/progress-bar-desktop.png");
      background-size: cover;
      border-radius: 5px;
      margin-right: 5px;
      position: relative;
      display: flex;
      align-items: center;
      min-height: 60px;
      .cup {
        position: absolute;
        right: -15px;
        z-index: 1;
        width: 30px;
      }
      .progress {
        background: url("../../assets/backgrounds/progress-bar-complete-desktop.png");
        background-size: cover;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        position: relative;
        min-width: 5%;
        .eu-flag {
          position: absolute;
          left: -15px;
          width: 30px;
        }
      }
    }
    .officer-container {
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: -40px;
      .info-container {
        justify-content: flex-start;
        margin-left: 3%;
        .text-container {
          padding: 0;
          max-width: 270px;
          background: url("../../assets/backgrounds/officer-wild-info.svg");
          background-size: cover;
          height: 128%;
          display: flex;
          flex-direction: column;
          align-items: center;
          .title-container {
            width: 80%;
            margin-top: 40px;
            font-size: 26px;
            .title {
              line-height: 26px;
              font-weight: 300;
              padding: 5px 0px 5px 15px;
              letter-spacing: 0;
            }
          }
          .content-container {
            width: 80%;
            .content {
              padding-left: 15px;
              color: #979797;
              font-size: 13px;
              line-height: 17px;
              font-weight: 300;
              letter-spacing: 0.23px;
            }
          }
        }
      }
    }
  }
  .mobile-device-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    .menu-container {
      position: fixed;
      top: 2%;
      right: 3%;
      display: flex;
      .question-mark-container {
        display: flex;
        .question-mark {
          margin-right: 5px;
        }
        .label {
          color: #fff;
          display: flex;
          align-items: center;
          white-space: nowrap;
          margin-right: 20px;
        }
      }
    }
    .top-container {
      position: absolute;
      top: 15%;
      width: 80%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .text-container {
        text-align: center;
        font-size: 21px;
        font-weight: 700;
        color: #fff;
      }
      .icon-container {
        display: flex;
        align-items: center;
        flex-direction: column;
        .cup-container {
          background: #fbc008;
          padding: 15px;
          border-radius: 50%;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          .cup {
            display: block;
            position: absolute;
            top: -20px;
          }
        }
        .label {
          color: #fff;
        }
      }
    }
    .image-container {
      width: 180%;
    }
    .image-container-complete {
      position: absolute;
      bottom: -6px;
    }
    .progressive-bar-root {
      width: 80%;
      .progresive-bar-container {
        background: url("../../assets/backgrounds/progress-bar.png");
        background-size: contain;
        background-repeat: no-repeat;
        border-radius: 5px;
        margin-right: 5px;
        position: relative;
        display: flex;
        align-items: center;
        .cup {
          position: absolute;
          right: -18px;
          z-index: 1;
          width: 13%;
        }
        .progress {
          background: url("../../assets/backgrounds/progress-bar-complete.png");
          background-size: cover;
          position: relative;
          min-height: 15vw;
          display: flex;
          align-items: center;
          .eu-flag {
            position: absolute;
            left: -18px;
            width: 10vw;
          }
        }
      }
      .progress-number-container {
        .progress-number {
          display: flex;
          justify-content: flex-end;
          color: #ffbd08;
          margin-top: 5px;
          font-size: 24px;
          font-weight: 700;
        }
      }
    }
  }
}
