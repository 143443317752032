.region-and-flag-list-root {
  height: 100%;
  width: 100%;
  overflow: hidden;

  .region-and-flag-list-container {
    height: 100%;
    overflow-y: auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    padding: 0 40px 0 40px;

    & div {
      display: flex;
    }

    .loading-indicator {
      position: relative;
      top: 50%;
      left: 50%;
    }
  }
}
