.region-and-flag-root-container {
  .region-and-flag-container {
    display: flex;
    flex-direction: row;

    cursor: pointer;
    .flag {
      padding: 5px;
      border-radius: 50%;
      width: 40px;
      height: 40px;
    }
    .region {
      margin-left: 10px;
      display: flex;
      align-items: center;
      color: #878787;
      &.region:hover {
        color: #2e3071;
      }
    }
  }
}
