@import "../../styles/colors";

.quiz-score-root-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  margin-bottom: 100px;
  &.mobile-version {
    margin-bottom: 0;
  }

  .timer-top-container {
    height: 33%;
    min-height: 322px;
    width: 100%;
    display: flex;
    justify-content: center;
    .background-container {
      height: 100%;
      width: 820px;
      background: url("../../assets/backgrounds/timer-background.png") no-repeat;
      background-size: cover;
      display: flex;
      flex-direction: column;
      align-items: center;

      .timer-container {
        transform: skewY(-1deg);
        color: #ffffff;
        letter-spacing: 3.38px;
        z-index: 300;
        position: relative;
        top: 15%;
        .timer {
          display: flex;
          align-items: flex-end;
          transform: rotate(-3deg);
          .unit {
            font-size: 48px;
            margin-bottom: 10px;
          }
        }
        h1 {
          font-weight: 500;
        }
      }

      .shape-container {
        width: 80%;
        background: url("../../assets/backgrounds/timer-shape.png") no-repeat;
        background-size: cover;
        color: map-get($colors, primary);
        height: 65%;
        transform: skewY(0deg);
        display: flex;
        padding: 32px 32px 16px 32px;
        justify-content: end;
        flex-direction: column-reverse;
        z-index: 200;
        position: relative;
        top: -45px;
        .info-text-container {
          font-size: 16px;
          letter-spacing: 0.3px;
          text-align: center;
          line-height: 21px;
          padding: 0px 60px;
          font-weight: 700;
          height: 100%;
          display: flex;
          align-items: center;
          transform: rotate(-3deg);
        }

        .report-amount {
          background: linear-gradient(to top, #fff 45%, transparent 45%);
          width: fit-content;
          padding: 0 5px;
        }

        .asterix {
          position: absolute;
          top: -17px;
          right: -15px;
        }
      }
    }
  }

  .report-bottom-container {
    height: 20%;
    min-height: 195px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .report-container {
      background: url("../../assets/backgrounds/report.png") no-repeat;
      background-size: cover;
      min-width: 650px;
      width: 38%;
      height: 105%;
      padding: 0 32px;
      z-index: 0;

      .columns-container {
        height: 75%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;

        .report-column {
          flex: 2;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          color: #fff;
          margin: 0 20px;

          .subtitle {
            font-size: 16px;
            font-weight: 500;
            width: 100%;
          }

          .main-info {
            font-size: 32px;
            font-weight: 800;
          }

          .numbers-container {
            font-size: 16px;
            display: flex;
            align-items: center;
            font-weight: 600;
            width: 100%;
          }
          .numbers-flag {
            width: 25px;
            margin-right: 10px;
          }
        }
      }
      .detailled-report-button-container {
        height: 25%;
        .detailled-report-button {
          border-radius: 50px;
          position: relative;
          left: 50%;
          top: 50%;
          transform: translateX(-50%);
          .MuiButton-label {
            text-transform: none;
          }
          .MuiSvgIcon-root {
            opacity: 0.25;
            font-size: 1.3rem;
          }
        }
      }
    }
  }

  .root-mobile-container {
    padding-bottom: 61px;
    height: 100%;
    width: calc(100% - 10px);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    margin-top: 100px;
    .mobile-timer-container {
      background: url("../../assets/backgrounds/timer-background-mobile.png")
        no-repeat;
      background-size: cover;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-height: 190px;
      width: 100%;

      .timer-shape-mobile {
        transform: skewY(-2deg);
        color: #ffffff;
        letter-spacing: 3.38px;
        background: url("../../assets/backgrounds/timer-shape.png") no-repeat;
        background-size: cover;
        height: 50%;
        padding: 8px 5px;
        width: calc(100% - 50px);
        display: flex;
        justify-content: center;
        align-items: center;
        .timer {
          transform: rotate(-4deg);
          display: flex;
          align-items: flex-end;
        }
        .unit {
          font-size: 28px;
        }
        .asterix {
          position: absolute;
          top: -17px;
          right: -15px;
        }
        h2 {
          font-weight: 500;
        }
      }
    }

    .detailled-report-button-container {
      .detailled-report-button {
        border-radius: 50px;
        margin: 10px 0 20px 0;
        .MuiButton-label {
          text-transform: none;
        }
        .MuiSvgIcon-root {
          opacity: 0.25;
          font-size: 1.3rem;
        }
      }
    }

    .info-text-container {
      color: #fff;
      text-align: center;
      padding: 16px 16px 0 16px;
      font-size: 14px;
      letter-spacing: 0.26px;
      line-height: 18px;

      .report-amount {
        color: #ffc200;
        width: fit-content;
        padding: 0 5px;
      }
    }

    .mobile-report-bottom-container {
      display: flex;
      background: url("../../assets/backgrounds/report.png") no-repeat;
      background-size: cover;
      padding: 0 16px;
      min-height: 150px;
      width: calc(100% - 30px);

      .report-container {
        width: 100%;
        .columns-container {
          height: 75%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .report-column {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            color: #fff;

            .subtitle {
              font-size: 12px;
              font-weight: 500;
              letter-spacing: 0.19px;
              position: relative;
              width: 100%;
            }

            .main-info {
              font-size: 16px;
              font-weight: 800;
              color: #ffffff;
              letter-spacing: 0.38px;
              line-height: 30px;
            }

            .numbers-container {
              font-size: 12px;
              display: flex;
              align-items: center;
              font-weight: 600;
              letter-spacing: 0.56px;
              position: relative;
              width: 100%;
            }
            .numbers-flag {
              width: 25px;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  .buttons-container {
    display: flex;
    flex-direction: row;
    position: absolute;
    bottom: 5%;
    width: 20%;
    justify-content: space-around;
    .contact-us-button-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      .label {
        color: #7a7f9c;
        margin-top: 5px;
        text-align: center;
      }
      .MuiFab-colorInherit {
        background: #3e446a !important;
        -webkit-box-shadow: 0px 0px 0px 14px rgba(0, 0, 0, 0.05);
        box-shadow: 0px 0px 0px 14px rgba(0, 0, 0, 0.05);
      }
    }
    .global-ranking-button-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      .label {
        color: #e7e7e7;
        margin-top: 5px;
        text-align: center;
      }
      .MuiFab-colorInherit {
        background: #131a42 !important;
        -webkit-box-shadow: 0px 0px 0px 14px rgba(0, 0, 0, 0.05);
        box-shadow: 0px 0px 0px 14px rgba(0, 0, 0, 0.05);
      }
    }
  }
}
