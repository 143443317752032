@import "../../styles/colors";

.question-root-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  width: 100%;

  .title-root-container {
    &.transformed-title {
      transform: rotate(-0.5deg);
    }
  }
  .quiz-progress {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .label {
      color: #fff;
      margin-bottom: 5px;
    }
    .done-questions {
      color: #fff;
      font-size: 21px;
      background: #131a42;
      border-radius: 50%;
      width: 49px;
      height: 49px;
      border: dashed 10px rgba(0, 0, 0, 0.07);
      -webkit-box-shadow: 0px 0px 0px 14px rgba(0, 0, 0, 0.05);
      box-shadow: 0px 0px 0px 14px rgba(0, 0, 0, 0.05);
      display: flex;
      justify-content: center;
      position: relative;
      &.mobile-version {
        width: 35px;
        height: 35px;
        font-size: 14px;
      }
      .total-questions {
        color: #fff;
        font-size: 18px;
        padding-top: 5px;
        position: absolute;
        right: -35px;
        top: -5px;
        &.mobile-version {
          font-size: 9px;
          right: -20px;
          top: -3px;
        }
      }
    }
  }
  .center-container {
    @media screen and (max-width: 480px) {
      width: 70vw;
      padding-top: 30%;
      padding-bottom: 25%;
    }
    width: 65vw !important;
    &.mobile-version {
      width: 80vw !important;
    }

    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    .answers-container {
      display: flex;
      flex-direction: row;
      margin-top: 5%;
      justify-content: space-between;
      width: 75%;
      flex-wrap: wrap;
      &.mobile-version {
        width: 100%;
      }
      .answer-container {
        padding: 20px;
        cursor: pointer;
        border-radius: 5px;
        text-align: center;
        transform: skew(-2deg);
        display: flex;
        justify-content: center;
        height: 10vh;
        align-items: center;

        &.answer-container-on-desktop {
          width: 45%;
          margin: 0 20px 20px 0;
          display: flex;
          flex-grow: 1;
          &:nth-child(1) {
            background: url("../../assets/backgrounds/answers/answer1.svg")
              no-repeat;
            background-size: cover;
            &:hover {
              background: url("../../assets/backgrounds/answers/answer1-hover.svg")
                no-repeat;
              background-size: cover;
            }
          }
          &:nth-child(2) {
            background: url("../../assets/backgrounds/answers/answer2.svg")
              no-repeat;
            background-size: cover;
            &:hover {
              background: url("../../assets/backgrounds/answers/answer2-hover.svg")
                no-repeat;
              background-size: cover;
            }
          }
          &:nth-child(3) {
            background: url("../../assets/backgrounds/answers/answer3.svg")
              no-repeat;
            background-size: cover;
            &:hover {
              background: url("../../assets/backgrounds/answers/answer3-hover.svg")
                no-repeat;
              background-size: cover;
            }
          }
          &:nth-child(4) {
            background: url("../../assets/backgrounds/answers/answer4.svg")
              no-repeat;
            background-size: cover;
            &:hover {
              background: url("../../assets/backgrounds/answers/answer4-hover.svg")
                no-repeat;
              background-size: cover;
            }
          }
        }
        &.answer-container-on-mobile {
          width: 100%;
          margin-bottom: 20px;
          background: url("../../assets/backgrounds/answers/answer-mobile.svg")
            no-repeat;
          background-size: cover;
        }
        .answer {
          color: #717388;
        }
      }
      .selected-answer-container {
        padding: 20px;
        cursor: pointer;
        border-radius: 5px;
        position: relative;
        display: flex;
        justify-content: center;
        transform: skew(-2deg);
        text-align: center;
        height: 10vh;
        align-items: center;

        &.answer-container-on-desktop {
          width: 45%;
          margin: 0 20px 20px 0;
          display: flex;
          flex-grow: 1;
          &:nth-child(1) {
            background: url("../../assets/backgrounds/answers/answer1-selected.svg")
              no-repeat;
            background-size: cover;
          }
          &:nth-child(2) {
            background: url("../../assets/backgrounds/answers/answer2-selected.svg")
              no-repeat;
            background-size: cover;
          }
          &:nth-child(3) {
            background: url("../../assets/backgrounds/answers/answer3-selected.svg")
              no-repeat;
            background-size: cover;
          }
          &:nth-child(4) {
            background: url("../../assets/backgrounds/answers/answer4-selected.svg")
              no-repeat;
            background-size: cover;
          }
        }
        &.answer-container-on-mobile {
          width: 100%;
          margin-bottom: 20px;
          background: url("../../assets/backgrounds/answers/answer-selected-mobile.svg")
            no-repeat;
          background-size: cover;
        }
        .check {
          padding: 5px;
          background-color: #fff;
          border-radius: 50%;
          position: absolute;
          bottom: -10px;
        }
      }
    }
    .buttons-container {
      position: absolute;
      bottom: 5%;
      margin-right: 40px;
    }
    .continue-button-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .arrow {
        padding: 20px;
        background-color: #131a42;
        border-radius: 50%;
        cursor: pointer;
        &.disabled {
          cursor: not-allowed;
        }
      }

      .label {
        color: #fff;
        margin-top: 5px;
      }
    }
    .submit-button-container {
      margin-bottom: 5%;
      .submit {
        padding: 15px 40px 15px 40px;
        border-radius: 1px;
        cursor: pointer;
        text-transform: none;
        font-size: 17px;
        #rectangle {
          width: 160px;
          height: 65px;
          border-radius: 1px;
          background: #ffc200;
          box-shadow: -8px 8px 4px 4px rgba(0, 0, 0, 0.1);
        }

        &.disabled {
          &:hover {
            cursor: not-allowed;
          }
          opacity: 0.8;
          background-color: map-get($colors, secondary);
        }
      }
    }
  }
}
