@import "../../styles/colors";

.customPrimaryText {
  span {
    font-size: 12px !important;
  }
}
.quiz-button-label {
  width: 100%;
  text-align: center;
  right: 0px;
  position: absolute;
  bottom: 160%;
  color: #dedee1;
}
.take-quiz-button-label {
  position: absolute;
  bottom: 90px;
  left: 0px;
  color: #dedee1;
  width: 100%;
  display: flex;
  justify-content: center;
}

.wrong-answer {
  .MuiFab-colorInherit {
    color: #d5d5d5 !important;
    background: #4f1212 !important;
  }
}

.correct-answer {
  .MuiFab-colorInherit {
    color: #d5d5d5 !important;
    background: #033626 !important;
  }
}

.submit {
  .MuiFab-colorInherit {
    color: #2a305f !important;
    background-color: map-get($colors, secondary) !important;
  }
}

.primary {
  .MuiFab-colorInherit {
    color: #d5d5d5 !important;
    background: #131a42 !important;
  }
}

.secondary {
  .MuiFab-colorInherit {
    color: #2a305f !important;
    background: #ffc200 !important;
  }
}

.global-ranking {
  .MuiFab-colorInherit {
    background-color: map-get($colors, secondary) !important;
  }
}

@-webkit-keyframes slide {
  100% {
    left: 0;
  }
}

@keyframes slide {
  100% {
    left: 0;
  }
}

.close-button-container {
  z-index: 1000;
  position: absolute;
  right: 15px;
  top: 15px;
  .MuiFab-colorInherit {
    background-color: #FFFFFF !important;
    color: map-get($colors, primary) !important;
  }

  .MuiFab-label {
    .MuiSvgIcon-root {
      height: 18px;
      width: 18px;
    }
  }
}
