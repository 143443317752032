.mobile-menu-root-container {
  .menu {
    border-radius: 5px;
    background-color: #131a42;
    padding: 15px;
    display: flex;
    transform: scaleX(-1);
    &.wrong-answer {
      background: #4f1212 !important;
    }
    &.correct-answer {
      background: #033626 !important;
    }
  }
}

.customMobileMenuPopupPaper {
  width: 100%;
  top: 0 !important;
  max-width: 100% !important;
  left: 0 !important;
}
