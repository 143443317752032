.quiz-description-root-container {
  display: flex;
  width: 100%;
  height: 100%;
  &.mobile-version {
    flex-direction: column;
  }
  .info-container {
    display: flex;
    justify-content: flex-end;
    position: relative;
    right: 20%;
    width: 100%;
    &.mobile-version {
      right: 10%;
    }
    .info-content {

      background: #FFF;
      top: 14%;
      height: 55%;
      width: 62%;
      border-radius: 2px;

      position: relative;
      &.mobile-version {
        background: url("../../assets/backgrounds/quiz-info-container-mobile.svg");
        background-size: cover;
        background-repeat: no-repeat;
        width: 82%;
        min-height: 800px;
      }
      .title-container {
        .title {
          white-space: nowrap;
          background: linear-gradient(to top, #040718 45%, transparent 45%);
          color: #fff;
          position: absolute;
          top: -13%;
          left: -3%;
          font-size: 3vw;
          text-align: center;
          padding: 0 50px;
          &.mobile-version {
            padding: 0 15px;
            top: -6px;
            left: 0;
            font-size: 4.3vw;
          }
        }
      }
      .content {
        position: relative;
        z-index: 20;
        top: 15%;
        padding: 0 3%;
        &.mobile-version {
          top: 5%;
          height: 90%;
          padding: 0 15%;
        }
        .quiz-info {
          font-size: 1vw;
          text-align: center;
          padding: 0 5%;
          margin-bottom: 3%;
          &.mobile-version {
            font-size: 3vw;
            height: 140px;
            margin-bottom: 0;
          }
        }
        .informations-root {
          .icons-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 10%;
            .line {
              border-style: none;
              border-top-style: dotted;
              border-color: #d8d8d8;
              border-width: 5px;
              width: 15%;
              &.mobile-version {
                border-top-style: none;
                border-left-style: dotted;
                height: 10%;
              }
            }
            &.mobile-version {
              flex-direction: column;
              padding: 0;
              margin-bottom: 70px;
              margin-right: 20px;
            }
            .icon-mobile-version {
              width: 15vw;
            }

            .icon-desktop-version {
              height: 10vh;
              width: 10vw;
            }
          }
          .informations-container {
            display: flex;
            justify-content: space-between;
            .quiz-info-content {
              width: 30%;
              display: flex;
              flex-direction: column;
              align-items: center;
              &.mobile-version {
                width: 100%;
                position: relative;

                &:nth-child(1) {
                  top: 6%
                }
                &:nth-child(2) {
                  top: 12%
                }
                &:nth-child(3) {
                  top: 20%
                }
              }
              .title {
                white-space: nowrap;
                color: #040e1f;
                font-size: 1vw;
                &.mobile-version {
                  font-size: 3vw;
                  width: 100%;
                }
              }
              .information {
                text-align: center;
                color: #9fa4b5;
                font-size: 0.9vw;
                &.mobile-version {
                  font-size: 3vw;
                  text-align: left;
                }
              }
            }
            &.mobile-version {
              flex-direction: column;
              margin-bottom: 70px;
            }
          }
          &.mobile-version {
            display: flex;
            flex-direction: row;
            height: 65%;
          }
        }
      }

      .bubble-triangle {
        position: absolute;
        z-index: 10;
        bottom: -27px;
        right: 20%;
        transform: rotate(32deg);
        width: 0;
        height: 0;
        border-left: 50px solid transparent;
        border-right: 69px solid transparent;
        border-bottom: 100px solid #FFFF;
        border-radius: 4px;
      }
    }
  }
  .officer-wild-container {
    position: absolute;
    bottom: 0;
    right: 15%;
    &.mobile-version {
      margin-bottom: 50px;
      position: relative;
      left: 20px;
      width: 80%;
    }
    .officer {
      width: 20vh;
      &.mobile-version {
        width: 15vh;
      }
    }
  }
}
