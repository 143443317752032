@import "../../styles/colors";

.chapter-sections-page-root {
  min-height: 100%;
  background: #181c34;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: PTSans;

  .title {
    @media screen and (max-width: 480px) {
      width: 100%;
      margin: 0px 0 25px 0;
      font-size: 40px;
      line-height: 0;
    }
    display: flex;
    margin: 50px 0 50px 0;
    width: 70%;
  }
  .chapter-sections-container {
    &.mobile-chapter-sections-container {
      padding-left: 0;
      width: 100%;
    }
    width: 80%;
    padding-left: 30px;

    .section-content {
      margin-bottom: 30px;
      .MuiAccordion-root {
        .MuiAccordionSummary-root {
          @media screen and (max-width: 480px) {
            padding: 0px 16px 0px 0px !important;
          }
        }
      }

      .section-header {
        &.fade-section-header {
          color: #565A6F;
        }
        display: flex;
        color: #fff;
        margin-bottom: 12px;

        .number-container {
          @media screen and (max-width: 480px) {
            margin-right: 12px;
            font-size: 16px;
            letter-spacing: 0.22px;
            line-height: 19px;
          }

          min-width: 60px;
          text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.06);
          font-size: 21px;
          line-height: 28px;
          letter-spacing: 0.875px;
          .section-number {
            font-weight: 600;
            font-family: NexaBold;
          }
        }
        .section-info-container {
          .section-title-container {
            display: flex;
            .section-title {
              @media screen and (max-width: 480px) {
                font-size: 16px;
                letter-spacing: 0.22px;
                line-height: 1.2
              }

              font-weight: 600;
              font-family: PTSans;
              font-size: 24px;
              letter-spacing: 0;
              line-height: 1.2
            }
          }
          .summary-container {
            margin-top: 10px;
            font-weight: 600;
            font-size: 14.5px;
            letter-spacing: 0;
            line-height: 20px;
            font-family: PTSans;
            color: #9FA4B5;
          }
        }
      }

      .MuiPaper-elevation1 {
        box-shadow: none;
      }
      .section-container {
        &.sections-container-no-margin {
          margin-left: 0px;
        }
        @media screen and (max-width: 480px) {
          margin-left: 20px;
        }
        margin-left: 56px;
        width: 100%;

        .chapter-content {
          @media screen and (max-width: 480px) {
            font-size: 14.5px !important;
            letter-spacing: 0 !important;
            line-height: 21px !important;
          }

          width: 100%;
          color: #DEDEDE;
          font-size: 16.5px;
          letter-spacing: 0;
          line-height: 23px;
          font-weight: 400;
          font-family: PTSans;

          blockquote {
            background: #fff;
            color: #000;
            margin: 0 0 30px 0;
            padding: 20px;
            transform: rotate(-1deg);
            width: 100%;
            display: flex;
            @media screen and (max-width: 480px) {
              flex-direction: column;
            }
            p {
              transform: rotate(1deg);
              color: map-get($colors, primary);
              font-weight: 600;

              img {
                @media screen and (max-width: 480px) {
                  margin-top: unset;
                  margin-right: unset;
                }
                margin-top: -70px;
                margin-right: -45px;
                margin-bottom: -70px;
              }
            }
          }
          p {

            span {
              color: #FCBE08;
            }

            a:link {
              color: #FCBE08;
            }

            /* visited link */
            a:visited {
              color: #FCBE08;
            }

            /* mouse over link */
            a:hover {
              color: #FCBE08;
            }

            /* selected link */
            a:active {
              color: #FCBE08;
            }

          }
        }
        .bottom-section-container {
          height: 200px;
          display: flex;
          flex-direction: column-reverse;
          .officer-wild-container-take-quiz {
            &:hover {
              cursor: pointer;
            }

            display: flex;
            flex-direction: row;
            justify-content: space-between;
            background-color: map-get($colors, secondary);
            height: 50%;
            transform: skewY(-0.5deg);
            .officer-wild {
              display: flex;
              width: 30%;
              margin-right: 10px;
            }
            .officer-wild-mobile {
              position: relative;
              left: -20px;
              img.officer {
                height: 300%;
              }
            }
            .officer-wild-container {
              height: 55%;
            }

            .text-container {
              width: 60%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              padding-left: 20px;

              .small-text {
                font-size: 14px;
                p {
                  @media screen and (max-width: 480px) {
                    font-size: 12px;
                  }
                  line-height: 1.1;
                  margin-bottom: 10px;
                }
              }
              .large-text {
                margin: 0px;
                color: #fff;
                font-size: 26px;
                h5 {
                  @media screen and (max-width: 480px) {
                    font-size: 16px;
                  }
                  line-height: 1.1;
                }
              }
            }

            .arrow-container {
              width: 10%;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              margin-right: 15px;
              color: #fff;

              .arrow-icon {
                font-size: 16px;
              }
            }
          }
        }
      }
    }

    .MuiAccordionSummary-expandIcon {
      color: #fff;
      box-shadow: inset 0px 0px 3px;
      @media (hover: hover) {
        &:hover {
          background: #3e446a;
          box-shadow: 0px 0px 0px 10px rgba(0, 0, 0, 0.05);
        }
      }
    }

    .Mui-expanded {
      .MuiAccordionSummary-expandIcon {
        background: #3e446a;
        box-shadow: 0px 0px 0px 10px rgba(0, 0, 0, 0.05);
      }
    }
    .MuiAccordionSummary-expandIcon {
      @media screen and (max-width: 480px) {
        height: 40px;
        width: 40px;
      }
      height: 49px;
      width: 49px;

      .MuiSvgIcon-root {
        height: 16px;
        width: 16px;
      }
    }

    .MuiAccordion-root {
      background: transparent;
    }
    .MuiAccordionSummary-content,
    .MuiAccordionDetails-root {
      color: #9fa4b5;
      margin-right: 52px;
      margin-top: 0px;
      margin-bottom: 0px;
      padding-top: 0px;
      font-size: 14.5px;
      letter-spacing: 0;
      line-height: 20px;
    }
  }
}
