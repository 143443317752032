@import "../../styles/colors";

.campaign-page-root-container {
  display: flex;

  &.desktop-version {
    height: 100%;
  }

  &.detailed-report {
    min-height: 100%;
  }

  &.mobile-version {
    min-height: 100%;
  }

  .intro-page-root-container {
    &.dark-background {
      background: url("../../assets/backgrounds/zebra-blue.png") no-repeat;
      background-size: cover;
      animation: fadeIn 1s;
      -webkit-animation: fadeIn 1s;
      -moz-animation: fadeIn 1s;
      -o-animation: fadeIn 1s;
      -ms-animation: fadeIn 1s;
    }
    min-height: 100%;
    width: 100%;
    background: url("../../assets/backgrounds/zebra-yellow.png") no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    flex-direction: column;
    animation: fadeIn 1s;
    -webkit-animation: fadeIn 1s;
    -moz-animation: fadeIn 1s;
    -o-animation: fadeIn 1s;
    -ms-animation: fadeIn 1s;
    transition: 1s linear;

    &.desktop-layout {
      position: relative;
    }

    .officer-wild-intro-page-container {
      height: 50%;
      position: absolute;
      bottom: 0;
      right: 5%;
      .title {
        line-height: 26px;
        font-weight: 300;
      }
      .content-container {
        .content {
          color: #979797;
          font-size: 13px;
          line-height: 17px;
          font-weight: 300;
        }
      }
    }
    .take-quiz-button-container {
      position: absolute;
      bottom: 5%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .label {
        color: #dedee1;
        margin-top: 5px;
      }
    }
    .quiz-button-container {
      position: absolute;
      bottom: 5%;
      display: flex;
      flex-direction: column;
      align-items: center;

      button {
        background: #131a42;

        &:hover {
          background: map-get($colors, primary);
          -webkit-box-shadow: 0px 0px 0px 14px rgba(0, 0, 0, 0.05);
          box-shadow: 0px 0px 0px 14px rgba(0, 0, 0, 0.05);
        }
      }
      .label {
        color: #dedee1;
        margin-top: 5px;
      }
    }

    .language-selection {
      position: absolute;
      top: 3%;
      right: 3%;
      &.mobile-version {
        margin-right: 15%;
      }
    }

    .menu-container {
      position: fixed;
      top: 2%;
      right: 3%;
    }

    .center-container {
      @media screen and (max-width: 480px) {
        width: 70vw;
      }
      @media screen and (max-width: 1490px) {
        width: 60%;
      }

      @media screen and (max-width: 1244px) {
        width: 85%;
      }

      width: 45%;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
    }

    .bottom-container {
      &.logo-reveal {
        animation: reveal 2s backwards;
        -webkit-animation-delay: 0.5s;
        animation-delay: 0.5s;
      }
      .eucpn-logo {
        margin-right: 10px;
      }

      display: flex;
      height: 48px;
      &.bottom-container-mobile {
        display: flex;
        justify-content: center;
        width: 100%;
        img {
          max-height: 75%;
        }
      }

      margin-top: 24px;
      width: 40vw;
      img {
        max-width: 100%;
        max-height: 100%;
        display: block; /* remove extra space below image */
      }
    }

    .quiz-root-container {
      .dialog-enter {
        opacity: 0.01;
        transform: scale(1.1);
      }

      .dialog-enter-active {
        opacity: 1;
        transform: scale(1);
        transition: all 300ms;
      }

      .dialog-exit {
        opacity: 1;
        transform: scale(1);
      }

      .dialog-exit-active {
        opacity: 0.01;
        transform: scale(1.1);
        transition: all 300ms;
      }
    }
  }
}
