html, body { height: 100%; }
#root {
  height: 100%;
}
body {
  margin: 0;
  font-family: 'NexaRegular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'NexaRegular'
}
@font-face {
  font-family: 'NexaRegular';
  /* src: url("https://eucpn.noticed.be/build/fonts/Fontfabric/Fontfabric - Nexa Regular.otf") format("opentype"); */
  src: url("https://eucpn.noticed.be/font/Fontfabric - Nexa Regular.otf") format("opentype");
}

/* .nexa-block { font-family: 'Nexa Regular', arial; } */
/* @font-face {
  font-family: 'NexaBold';
  src: local('NexaBold'), url(./fonts/NexaBold.otf) format('opentype');
  font-weight: 400;
} */
@font-face {
  font-family: 'NexaBold';
  src: local('NexaBold'), url(./fonts/NexaRegular.ttf) format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'NexaBold';
  src: local('NexaBold'), url(./fonts/NexaLight.otf) format('opentype');
  font-weight: 200;
}

@font-face {
  font-family: 'PTSans';
  src: local('PTSans'), url(./fonts/PTSans-BoldItalic.ttf) format('truetype');
  font-weight: bold;
  font-style: italic
}
@font-face {
  font-family: 'PTSans';
  src: local('PTSans'), url(./fonts/PTSans-Bold.ttf) format('truetype');
  font-weight: 600;
  font-style: italic
}
@font-face {
  font-family: 'PTSans';
  src: local('PTSans'), url(./fonts/PTSans-Italic.ttf) format('truetype');
  font-style: italic
}
@font-face {
  font-family: 'PTSans';
  src: local('PTSans'), url(./fonts/PTSans-Regular.ttf) format('truetype');
  font-weight: 400;
}
