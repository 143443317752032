@import "../../styles/colors";

.language-selection-root-container {
  display: flex;
  height: 5%;
  .desktop-or-laptop-device {
    display: flex;
    .background-container {
      background: #ffffff;
      border-right: 10px solid rgba(0, 0, 0, 0.03);
      border-radius: 2px;
    }
    .label-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 20px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      cursor: pointer;
      .label {
        font-size: 17px;
        letter-spacing: 0.27px;
        margin-left: 20px;
        color: map-get($colors, primary);
      }
    }
    .language-selection-container {
      display: flex;
      align-items: center;
      padding-left: 7px;
      background: rgba(19, 26, 66, 0.8);
      box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.05);
      border-radius: 2px;
    }
    .arrow {
      margin-right: 10px;
      cursor: pointer;
      padding: 10px;
    }
  }
  .mobile-device {
    display: flex;
    align-items: center;
    .flag {
      width: 35px;
    }
    .arrow {
      margin-right: 10px;
      padding: 10px;
    }
  }

  .region-and-flag-container {
    display: flex;
    margin-bottom: 0;
    cursor: default;
    .region {
      color: #fff;

      &.region:hover {
        color: #fff;
      }
    }
  }
}

.customLanguageSelectionPopupPaper {
  right: calc(3% - 8px) !important;
  margin-top: 35px !important;
  width: 23em;
  left: auto !important;
  .region-and-flag-container {
    display: flex;
    margin-bottom: 0;
    cursor: default;
    .region {
      color: #fff;
      font-size: 24px;
      &.region:hover {
        color: #fff;
      }
    }
    .flag {
      width: 35px;
    }
  }

  .language-selection {
    display: flex;

    .image-container {
      img {
        padding: 5px;
        width: 35px;
      }
    }
    .languages-container {
      margin-top: 8px;
    }
  }
}
