.customPopupPaper {
  left: 280px !important;
  top: 0px !important;
  width: calc(75% - 280px) !important;
  height: 100% !important;
  max-height: 100% !important;
  border-radius: 0px !important;

  .open-indicator{
    height: 14px;
    width: 14px;
    background: #FFF;
    transform: rotate(45deg);
    border-radius: 3px;
    position: fixed;
    left: 272px;
  }
}
.customPopupPaperMobile {
  top: 3px !important;
  left: 5px !important;
  width: calc(100% - 10px) !important;
  height: 100% !important;
  max-height: calc(100% - 70px) !important;
  max-width: calc(100% - 10px)  !important;
}

.typography-content {
  height: 100%;
  background-color: #191E48,
}

#simple-popover {
  z-index: 500 !important;
}
