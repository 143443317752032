@import "../../styles/colors";

.detailed-report-root-container {
  display: flex;
  height: 100%;
  width: 100%;
  padding: 5% 5% 0 5%;
  flex-direction: column;
  align-items: center;
  .questions-container {
    display: flex;
    background: url("../../assets/backgrounds/detailed-report.png");
    background-size: cover;
    width: 92%;
    position: relative;
    flex-direction: column;
    padding: 100px 40px 100px 40px;
    flex-grow: 1;
    margin-bottom: 50px;
    .header-container {
      display: flex;
      margin-bottom: 20px;
      .header-label {
        color: #ffc300;
        background: linear-gradient(to top, #181c3a 50%, transparent 45%);
        width: fit-content;
        padding: 0 10px;
      }
      .result {
        min-width: 50px;
        margin-right: 20px;
      }
      .question-result-container {
        display: flex;
        width: 70%;
      }
    }
    .title {
      position: absolute;
      top: -1vw;
      color: #fff;
      background: linear-gradient(to top, #181c3a 50%, transparent 45%);
      padding: 0 20px;
      transform: rotate(-1deg);
      &.mobile-version {
        top: -20px;
        left: 10px;
      }
    }
    &.mobile-version {
      flex-flow: column;
      background: url("../../assets/backgrounds/detailed-report-mobile.png");
      background-size: cover;
      margin-top: 30%;
      margin-bottom: 30%;
    }
    .back-button-container {
      position: absolute;
      right: 5%;
      top: -2vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      .MuiFab-colorInherit {
        color: #fff !important;
        background: #3f446a !important;
      }
      .label {
        color: #767d9b;
      }
    }
    .question-report-container {
      display: flex;
      flex-direction: column;
      .question-report-content {
        display: flex;
        align-items: center;
        .result-and-question-container {
          display: flex;
          width: 70%;
          min-width: 70%;
          &.mobile-version {
            width: 100%;
          }
        }
        &.mobile-version {
          width: 100%;
          flex-direction: column;
        }
        .result-container {
          display: flex;
          width: auto;
          margin-right: 20px;
          position: relative;
          min-width: 50px;
          min-height: 30px;
          align-items: center;

          &.mobile-version {
            align-items: flex-start;
            min-width: 30px;
          }
          .question-number {
            color: #fff;
            background: #131a42;
            width: 34.3px;
            height: 34.3px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            z-index: 1;
            left: -10px;
            &.mobile-version {
              width: 27.44px;
              height: 27.44px;
              font-size: 11px;
            }
          }
          .result {
            position: absolute;
            border-radius: 50%;
            width: 34.3px;
            height: 34.3px;

            display: flex;
            justify-content: center;
            padding: 10px;
            z-index: 0;
            right: 0;
            &.mobile-version {
              width: 27.44px;
              height: 27.44px;
              right: -10px;
            }
            &.wrong-result {
              background: #d65556;
            }
            &.correct-result {
              background: #129165;
            }
          }
        }
        .question {
          color: #fff;
          width: 80%;
          margin-right: 50px;
          &.mobile-version {
            width: 100%;
            margin: 0;
          }
        }
        .correct-answer {
          color: #fff;
          display: flex;
          flex-wrap: wrap;
          &.mobile-version {
            color: #ffc300;
            margin-bottom: 20px;
            width: 80%;
            margin-left: 25%;
          }
        }
      }
      .line {
        border: dashed 1px #3f4368;
        margin: 15px 0;
      }
    }
  }
  .email-container {
    display: flex;
    background: url("../../assets/backgrounds/email.png");
    background-size: cover;
    width: 92%;
    min-height: 200px;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &.mobile-version {
      margin-bottom: 30%;
      background: url("../../assets/backgrounds/email-mobile.svg");
      background-size: cover;
      margin-top: 5%;
      height: 35vh;
    }
    .info-text {
      color: #fff;
      width: 70%;
      &.mobile-version {
        text-align: center;
        margin-bottom: 20px;
      }
    }
    .submit-container {
      width: 70%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      &.mobile-version {
        flex-direction: column;
      }
      .email-address-container {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        .input-container {
          display: flex;
          align-items: center;
          .email {
            width: 10%;
          }
          .MuiInputBase-input {
            font-size: 30px;
            color: #ffffff;
          }
          &.mobile-version {
            .email {
              width: 20%;
            }
            .MuiInputBase-input {
              font-size: 18px;
              color: #ffffff;
            }
          }
        }
        .line {
          border: dashed 1px #3b3f5e;
        }
      }
      .submit-button-container {
        margin-left: 20px;
        &.mobile-version {
          width: 100%;
          margin-top: 20px;
        }
        .submit-button {
          background: #ffc200;
          border-radius: 1px;
          border: none;
          padding: 20px 40px;
          cursor: pointer;
          width: 100%;
          &.disabled {
            &:hover {
              cursor: not-allowed;
            }
            opacity: 0.8;
            background-color: map-get($colors, secondary);
          }
        }
      }
    }
  }
}
