@import "../../styles/colors";

.title-root-container {
  &.transformed-title {
    transform: rotate(-1deg);
  }
  &.centered-text {
    text-align: center;
  }
  .title-text-container {
    display: inline;
    font-weight: 400;

    &.small-size {
      font-size: 2vw;
    }
    &.medium-size {
      font-size: 3vw;
    }
    &.large-size {
      font-size: 3.5vw;
    }
    &.small-size-mobile {
      font-size: 7vw;
    }
    &.medium-size-mobile {
      font-size: 8vw;
    }
    &.large-size-mobile {
      font-size: 9vw;
    }
    &.dark-mode {
      div {
        &.animation-effect {
          animation: reveal 1.2s backwards;
          -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
        }
        display: inline;
        color: #fff;
        background: linear-gradient(
          to top,
          map-get($colors, secondary) 50%,
          transparent 45%
        );

        @media screen and (max-width: 480px) {
          padding: 0 5px;
        }
        padding: 0 20px;
        box-decoration-break: clone;
        -webkit-box-decoration-break: clone;
        -webkit-transition: all 0.5s linear;
        -moz-transition: all 0.5s linear;
        -o-transition: all 0.5s linear;
        transition: all 0.5s linear;
      }
    }

    &.light-mode {
      div {
        &.animation-effect {
          animation: reveal 1.2s backwards;
          -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
        }
        display: inline;
        color: map-get($colors, primary);
        background: linear-gradient(to top, #fff 50%, transparent 45%);
        @media screen and (max-width: 480px) {
          padding: 0 5px;
        }
        padding: 0 20px;
        -webkit-transition: all 0.5s linear;
        -moz-transition: all 0.5s linear;
        -o-transition: all 0.5s linear;
        transition: all 0.5s linear;
      }
    }
  }
}

@keyframes reveal {
  from {
    clip-path: inset(0 100% 0 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}
