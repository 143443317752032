.officer-wild-root-container {
  display: flex;
  justify-content: flex-end;
  height: 100%;
  .officer-wild-on-right-side {
    display: flex;
    justify-content: flex-end;
    height: 100%;
    flex-direction: column;
    .info-container {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      .text-container {
        padding: 20px 30px 30px 30px;
        max-width: 30%;
        margin-right: 5%;
        background: #FFF;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        position: relative;
        border-radius: 2px;
        transform: rotate(1deg);
        min-width: 270px;
        bottom: 20px;
        z-index: 20;

        .title-container {
          width: 75%;
          transform: rotate(-1deg);
          .title {
            color: #181d3d;
            margin-bottom: 5px;
            font-size: 26px;
          }
        }
        .content-container {
          display: flex;
          flex-grow: 1;
          transform: rotate(-1deg);
          .content {
            color: #979797;
          }
        }
        .snake {
          position: absolute;
          left: 0%;
          bottom: 15%;
          width: 25%;
        }
        .fingerprint {
          position: absolute;
          left: 5%;
          bottom: 0%;
        }
        .europe {
          position: absolute;
          left: 0;
          bottom: -10%;
        }
      }

      .bubble-triangle {
        position: absolute;
        z-index: 10;
        right: 10%;
        transform: rotate(27deg);
        width: 0;
        height: 0;
        border-left: 24px solid transparent;
        border-right: 40px solid transparent;
        border-bottom: 100px solid #FFFF;
        border-radius: 2px;
      }
    }
    .officer-wild-container {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }
  }
  .officer-wild-under-content {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
    .info-container {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      .text-container {
        padding: 30px 40px 40px 40px;
        max-width: 80%;
        background: url("../../assets/backgrounds/officer-wild-info.svg");
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        position: relative;
        .title-container {
          .title {
            color: #181d3d;
            margin-bottom: 5px;
            font-size: 26px;
          }
        }
        .content-container {
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          .content {
            color: #070c1f;
            font-weight: 700;
          }
          .read-more-button {
            background: transparent;
            border: none;
            color: #7a7f9c;
            font-weight: 700;
          }
        }
        .snake {
          position: absolute;
          right: -15%;
          top: -20%;
          z-index: 0;
        }
        .fingerprint {
          position: absolute;
          right: -10%;
          bottom: -10%;
          width: 35%;
        }
        .europe {
          position: absolute;
          right: -15%;
          bottom: -20%;
          width: 50%;
        }
      }
    }
    .officer-wild-container {
      display: flex;
      width: 100%;
    }
  }
}

.fade-in-officer-wild {
  animation: fadeIn 3s;
  -webkit-animation: fadeIn 3s;
  -moz-animation: fadeIn 3s;
  -o-animation: fadeIn 3s;
  -ms-animation: fadeIn 3s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
